import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import ReactMarkdown from 'react-markdown'

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query queryHomePageQuery {
      allStrapiHome {
        nodes {
          vision
          missionStatement
          values {
            id
            description
          }
        }
      }
    }
  `).allStrapiHome.nodes[0];
  return (
    <Layout>
      <SEO title="Home" />
      <div>
        <h3 className="heading">Our Mission Statement</h3>
        <div style={{ paddingBottom: "1em" }}>
          <ReactMarkdown source={data.missionStatement} />
        </div>
        <hr style={{ width: "50%", margin: "1em auto" }} />
        <h3 className="heading">Our Vision</h3>
        <div style={{ paddingBottom: "1em" }}>
          <ReactMarkdown source={data.vision} />
        </div>
        <hr style={{ width: "50%", margin: "1em auto" }} />
        <h3 className="heading">Our Values</h3>
        <div style={{ display: "flex", justifyContent: "center", flexWrap: "wrap" }}>
          {data.values && data.values.map(value => (
            <div className="value-element" key={value.id} style={{flex: "0 50%", boxSizing: "border-box"}}>{value.description}</div>
          ))}
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
